import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import {
  TextField,
  FormHelperText,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  Button,
  FormControl,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import SidebarMenu from "./Common/SidebarMenu";
import PhoneMenu from "./Common/PhoneMenu";
import LoaderImage from "../assets/images/spinner.gif";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const defaultTheme = createTheme();

const NotificationSettings = ({
  clientId,
  handleClose,
  updateNotification,
  isUpdateNotification,
}) => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("userRoleName");
  const userFirstName = localStorage.getItem("firstName");
  const userLastName = localStorage.getItem("lastName");
  const userEmail = localStorage.getItem("email");
  const [showLoader, setShowLoader] = useState(false);
  const timeZones = ["PST", "CST", "MST", "EST"];
  const [isTimezone, setIsTimezone] = useState("");
  const [isPhoneEnabled, setIsPhoneEnabled] = useState(false);
  const [isEmailEnabled, setIsEmailEnabled] = useState(false);
  const [confimModal, setConfrimModal] = useState(false);
  const [isSave, setIsSave] = useState(false);

  const dueDays_1_2 = ["90", "75", "60", "45", "30", "15"];
  const violationDays = ["-5", "-10", "-13", "-15", "-30"];
  const [isDue1Open, setIsDue1Open] = useState(false);
  const [isDue2Open, setIsDue2Open] = useState(false);
  const [isDue3Open, setIsDue3Open] = useState(false);
  const [isDue4Open, setIsDue4Open] = useState(false);

  const [isDue1Val, setIsDue1Val] = useState(0);
  const [isDue2Val, setIsDue2Val] = useState(0);
  const [isDue3Val, setIsDue3Val] = useState(0);
  const [isViolationVal, setViolationVal] = useState(0);

  useEffect(() => {
    setIsSave(false);
    // setShowLoader(true)
    axios({
      method: "GET",
      url: `${baseURL}/notifications?client_id=${clientId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        var filteredNotifications = [];
        if (role !== "masteradmin") {
          console.log("IN if");
          filteredNotifications = response.data.notifications.filter(
            (notification) => {
              return (
                notification.FirstName === userFirstName &&
                notification.LastName === userLastName &&
                notification.EmailAddress === userEmail
              );
            }
          );
        } else {
          console.log("IN else");
          filteredNotifications = [response.data.notifications[0]];
          console.log(filteredNotifications);
        }
        if (filteredNotifications.length > 0) {
          const notification = filteredNotifications[0];
          setIsEmailEnabled(notification.EmailEnabled);
          setIsPhoneEnabled(notification.PhoneEnabled);
          setIsTimezone(notification.TimeZone);
          setIsDue1Val(notification.DueDates1);
          setIsDue2Val(notification.DueDates2);
          setIsDue3Val(notification.DueOverdue);
          setViolationVal(notification.DueViolation);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }, [isSave, clientId]);

  const UpdatedMessageOptions = {
    title: "Notification settings are updated",
    overlayClassName: "overlay-custom-class-name successMessage",
    buttons: [
      {
        label: "Close",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          return false;
        },
      },
    ],
  };

  const selectTimezoneFunct = () => {
    axios({
      method: "PATCH",
      url: `${baseURL}/${clientId}/edit/notification/timezone`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        timeZone: isTimezone,
      },
    })
      .then((response) => {
        console.log({ response });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  const isEmailEnabledFunct = () => {
    axios({
      method: "PATCH",
      url: `${baseURL}/${clientId}/edit/notification/email/enable`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        firstName: userFirstName,
        lastName: userLastName,
        userEmail: userEmail,
        IsEmailEnabled: isEmailEnabled,
      },
    })
      .then((response) => {
        // console.log({response})
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  const isPhoneEnabledFunct = () => {
    axios({
      method: "PATCH",
      url: `${baseURL}/${clientId}/edit/notification/phone/enable`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        firstName: userFirstName,
        lastName: userLastName,
        userEmail: userEmail,
        isPhoneNumberEnabled: isPhoneEnabled,
      },
    })
      .then((response) => {
        console.log({ response });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  const due1DropdownClickFun = () => {
    axios({
      method: "PATCH",
      url: `${baseURL}/${clientId}/edit/notification/duedate1`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        DueDates1: isDue1Val,
      },
    })
      .then((response) => {
        setIsDue1Open(false);
        isUpdateNotification(true);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  const due2DropdownClickFun = () => {
    axios({
      method: "PATCH",
      url: `${baseURL}/${clientId}/edit/notification/duedate2`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        DueDates2: isDue2Val,
      },
    })
      .then((response) => {
        setIsDue2Open(false);
        isUpdateNotification(!updateNotification);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  const due4DropdownClickFun = () => {
    axios({
      method: "PATCH",
      url: `${baseURL}/${clientId}/edit/notification/dueviolation`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        DueViolation: isViolationVal,
      },
    })
      .then((response) => {})
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  const options = {
    // title: "Title",
    message: `Are you sure you want to cancel? \n Any changes will not be saved.`,
    buttons: [
      {
        label: "NO",
        className: "confimBtn",
        onClick: () => {
          setConfrimModal(false);
          return false;
        },
      },
      {
        label: "YES",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          handleClose();
        },
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: false,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  };

  const handleConfirmationBox = () => {
    setConfrimModal(true);
    if (!confimModal) {
      confirmAlert(options);
    }
  };

  const saveSettings = () => {
    selectTimezoneFunct();
    due1DropdownClickFun();
    due2DropdownClickFun();
    due4DropdownClickFun();
    isEmailEnabledFunct();
    isPhoneEnabledFunct();
    handleClose();
    confirmAlert(UpdatedMessageOptions);
    setIsSave(true);
  };

  // console.log({isDue1Val}, {isDue2Val}, {isDue3Val}, {isViolationVal});

  return (
    <>
      <Box className="notiFiMain">
        <Grid container spacing={0} padding={0} className="notiFiMain">
          <Container sx={{ mt: 0, mb: 2 }} className="notiFiMain">
            <Grid container spacing={0} className="topDueSect">
              {role !== "admin" && (
                <Grid item xs={8} style={{ paddingTop: 0 }}>
                  {showLoader && (
                    <Grid item className="loaderMain">
                      <img src={LoaderImage} alt="loaderImg" />
                    </Grid>
                  )}
                  <p>Select Timezone</p>
                  <TextField
                    // error={notificationData.timezone == ""}
                    id="outlined-select-timezone"
                    select
                    required
                    style={{ width: "85%", marginBottom: 20 }}
                    // label="Company Name"
                    helperText="Notifications are sent at 7am for your timezone"
                    value={isTimezone}
                    onChange={(e) => setIsTimezone(e.target.value)}
                  >
                    <MenuItem value="">None</MenuItem>
                    {timeZones.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              {role === "admin" && (
                <Grid style={{ paddingTop: 0 }}>
                  <p style={{ paddingBottom: 10 }}>Notification Method</p>
                  <FormControlLabel
                    style={{ width: 100 }}
                    control={
                      <Checkbox
                        name="isEmailEnabled"
                        checked={isEmailEnabled}
                      />
                    }
                    onChange={(e) => setIsEmailEnabled(e.target.checked)}
                    label="Email"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isPhoneEnabled"
                        checked={isPhoneEnabled}
                      />
                    }
                    label="Phone"
                    onChange={(e) => setIsPhoneEnabled(e.target.checked)}
                  />
                </Grid>
              )}
            </Grid>

            {role !== "admin" && (
              <Grid container spacing={2}>
                <Grid item xs={3} mt={1}>
                  <Typography fullWidth variant="p">
                    Due in 1
                  </Typography>
                  <Select
                    fullWidth
                    value={isDue1Val}
                    open={isDue1Open}
                    onClose={() => setIsDue1Open(false)}
                    onOpen={() => setIsDue1Open(true)}
                    onChange={(e) => setIsDue1Val(e.target.value)}
                  >
                    <MenuItem value={0}>Select One</MenuItem>
                    {dueDays_1_2.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item}>
                          {item} days
                        </MenuItem>
                      );
                    })}
                    <MenuItem value={0}>None</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={3} mt={1}>
                  <Typography variant="p">Due in 2</Typography>
                  <Select
                    fullWidth
                    value={isDue2Val}
                    open={isDue2Open}
                    onClose={() => setIsDue2Open(false)}
                    onOpen={() => setIsDue2Open(true)}
                    onChange={(e) => setIsDue2Val(e.target.value)}
                  >
                    <MenuItem value={0}>Select One</MenuItem>
                    {dueDays_1_2.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item}>
                          {item} days
                        </MenuItem>
                      );
                    })}
                    <MenuItem value={0}>None</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={3} mt={1}>
                  <Typography variant="p">Due in 3</Typography>
                  <Select
                    fullWidth
                    className="dueSectTop"
                    value={isDue3Val}
                    open={isDue3Open}
                    onClose={() => setIsDue3Open(false)}
                    onOpen={() => setIsDue3Open(true)}
                    onChange={(e) => setIsDue3Val(e.target.value)}
                  >
                    <MenuItem value="null">Select One</MenuItem>
                    <MenuItem value={0}>0 days</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={3} mt={1}>
                  <Typography variant="p">Violation</Typography>
                  <Select
                    className="dueSectTop"
                    fullWidth
                    value={isViolationVal}
                    open={isDue4Open}
                    onClose={() => setIsDue4Open(false)}
                    onOpen={() => setIsDue4Open(true)}
                    onChange={(e) => setViolationVal(e.target.value)}
                  >
                    <MenuItem value="">Select One</MenuItem>
                    {violationDays.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item}>
                          {item} days
                        </MenuItem>
                      );
                    })}
                    <MenuItem value="">None</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            )}
            <Box>
              {/* <SubmitAddDeviceModal /> */}
              {/* sx = {{ mr: 6, mt: 4, mb:4}} */}
              {/* <AddTestModal submitTestFileAndData={handleSubmit} openStatus={openSubmitModal} handleModalClose={handleCloseSubmitModal} alertContentText={alertContent}/> */}

              <Button
                // disabled={formIsValid ? false : true}
                type="submit"
                size="large"
                sx={{
                  ml: 0,
                  mr: 6,
                  mt: 4,
                  borderRadius: 8,
                  width: 225,
                }}
                variant="contained"
                color="info"
                onClick={saveSettings}
              >
                Save Settings
              </Button>

              <Button
                size="large"
                variant="contained"
                sx={{
                  ml: 0,
                  mr: 6,
                  mt: 4,
                  borderRadius: 8,
                  width: 225,
                }}
                color="inherit"
                onClick={handleConfirmationBox}
              >
                Cancel
              </Button>
            </Box>
          </Container>
        </Grid>
      </Box>
    </>
  );
};

export default NotificationSettings;
