import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MailMergeTableModal from "../components/LetterGeneration/MailMergeTableModal";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

export default function TemplateDropdown({
  clientId,
  clientName,
  selectedRowsData,
  selectedContactsData,
  closeGenerateLettersModal,
  currentFilter,
  isDue1Val,
  isDue2Val,
  isViolationVal,
}) {
  const [templateSelection, setTemplateSelection] = useState("");
  const [templateTypes, setTemplateTypes] = useState([]);
  const [mailMergeModalOpen, setMailMergeModalOpen] = useState(false);
  const handleOpen = () => setMailMergeModalOpen(true);
  const handleClose = () => {
    setMailMergeModalOpen(false);
    closeGenerateLettersModal();
  };
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const handleChange = (event) => {
    setTemplateSelection(event.target.value);
  };

  const handleSubmit = () => {
    console.log("Executing handle submit");

    console.log("Closed Modal");
    console.log(mailMergeModalOpen);

    handleOpen();
    console.log("Mail merge modal open", mailMergeModalOpen);
  };

  useEffect(() => {
    const fetchTemplateNames = async () => {
      try {
        const response = await fetch(
          `${baseURL}/${clientId}/${clientName}/getclienttemplatenames`
        );
        const data = await response.json();
        if (data?.data) {
          console.log(isDue1Val, isDue2Val, isViolationVal);
          if (isDue1Val === 30 || isDue1Val === 15) {
            isDue1Val = `${isDue1Val} day reminder`;
          }
          if (isDue2Val === 30 || isDue2Val === 15) {
            isDue2Val = `${isDue2Val} day reminder`;
          }
          var filterArray = [
            isDue1Val,
            isDue2Val,
            `Letter, ${isViolationVal}`,
            "Past",
            "New Device",
          ];
          console.log(filterArray);
          const filtered = data.data.filter((template) =>
            filterArray.some((filterValue) =>
              template.templateName.includes(filterValue)
            )
          );
          setTemplateTypes(filtered);
        } else {
          console.error("Unexpected data format:", data);
          setTemplateTypes([]);
        }
      } catch (error) {
        console.error("Error fetching template names:", error);
        setTemplateTypes([]);
      }
    };

    fetchTemplateNames();
  }, [baseURL, clientId, clientName]);

  useEffect(() => {
    console.log(currentFilter, "FILTER");
    var filterArray = [isDue1Val, isDue2Val, isViolationVal, "Past"];
    if (currentFilter === 1) {
      filterArray = ["Past"];
    } else {
      filterArray = Array.isArray(currentFilter)
        ? currentFilter
        : [currentFilter];
    }

    const filtered = templateTypes.filter((template) =>
      filterArray.some((filterValue) =>
        template.templateName.includes(`${filterValue}`)
      )
    );
    console.log(filtered[0]);
    const tempalte_list = filtered[0];
    if (tempalte_list) {
      setTemplateSelection(tempalte_list["templateName"]);
      setMailMergeModalOpen(true);
    }
  }, [templateTypes]);

  return (
    <Box sx={{ minWidth: 120 }}>
      <MailMergeTableModal
        clientId={clientId}
        clientName={clientName}
        selectedTemplate={templateSelection}
        openStatus={mailMergeModalOpen}
        closeStatus={handleClose}
        selectedRowsData={selectedRowsData}
        selectedContactsData={selectedContactsData}
      />
      {!currentFilter ? (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Template</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={templateSelection}
            label="Template"
            onChange={handleChange}
          >
            {templateTypes.map((option) => (
              <MenuItem key={option.ID} value={option.templateName}>
                {option.templateName}
              </MenuItem>
            ))}
          </Select>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button onClick={handleSubmit} variant="contained" sx={{ mt: 2 }}>
              Submit
            </Button>
            <Button
              onClick={closeGenerateLettersModal}
              variant="contained"
              sx={{ mt: 2, ml: 4 }}
            >
              Cancel
            </Button>
          </Grid>
        </FormControl>
      ) : (
        ""
      )}
    </Box>
  );
}
